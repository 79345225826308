<template>
  <div class="step-line invisible m-hide" :style="cssVars">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "v-dotted-line",
  props: {
    animationDelay: {
      type: String,
      default: "500",
    },
    svgWidth: {
      type: String,
      required: true,
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      rootMargin: "20px",
      threshold: 1.0,
    });
  },
  mounted() {
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  computed: {
    cssVars() {
      return {
        "--svg-width": `${this.svgWidth}px`,
      };
    },
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        this.observer.unobserve(target);
        this.addStylesToElement();
      });
    },
    addStylesToElement() {
      this.$el.classList.remove("invisible");

      const svgMask = this.$el.querySelector("svg mask");

      svgMask.classList.add("appear");
      svgMask.style.animationDelay = `${this.animationDelay}ms`;
    },
  },
};
</script>

<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}

.step-line {
  position: absolute;
  z-index: -1;
}

.appear {
  stroke-dasharray: var(--svg-width);
  stroke-dashoffset: var(--svg-width);
  animation: dash 0.5s linear forwards;
}

@keyframes dash {
  from {
    stroke-dashoffset: var(--svg-width);
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
